<template>
  <div class="h-screen flex w-full bg-img">
    <div class="vx-col w-4/5 sm:w-4/5 md:w-3/5 lg:w-3/4 xl:w-3/5 mx-auto self-center">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">
          <div class="vx-row">
            <div class="vx-col hidden sm:hidden md:hidden lg:block lg:w-1/2 mx-auto self-center">
              <img src="@/assets/images/pages/forgot-password.png" alt="login" class="mx-auto">
            </div>
            <div class="vx-col sm:w-full md:w-full lg:w-1/2 mx-auto self-center d-theme-dark-bg">
              <div class="p-8">
                <div class="vx-card__title mb-10">
                  <h4 class="mb-4">{{ $t('password.recovery.page.title') }}</h4>
                  <p>{{ $t('password.recovery.page.message') }}</p>
                </div>
                <ValidationObserver v-slot="{ invalid, handleSubmit }">
                  <form ref="resetPasswordForm" @submit.prevent="handleSubmit(sendLinkResetPassword)">
                    <ValidationProvider :name="$t('password.recovery.form.username')" rules="required" v-slot="{ errors }">
                      <vs-input
                        type="text"
                        name="username"
                        icon-no-border
                        icon="icon icon-lock"
                        icon-pack="feather"
                        :label-placeholder="$t('password.recovery.form.username')"
                        v-model="username"
                        class="w-full"/>
                      <div class="mt-1 mb-6 text-danger text-sm">{{ errors[0] }}</div>
                    </ValidationProvider>
                    <div v-if="isSend" class="mb-6">
                      <div class="text-warning font-medium">
                        {{ $t('password.recovery.form.email_send.title') }}
                      </div>
                      <div class="text-color-base text-sm">
                        {{ $t('password.recovery.form.email_send.text') }}
                      </div>
                    </div>
                    <vs-button button="submit" class="px-4 w-full mb-2">{{ $t('password.recovery.button.recover') }}</vs-button>
                    <vs-button type="border" to="/login" class="px-4 w-full">{{ $t('password.recovery.button.back_to_login') }}</vs-button>
                  </form>
                </ValidationObserver>
              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      username: '',
      isSend: false
    }
  },
  methods: {
    sendLinkResetPassword () {
      this.$store.dispatch('auth/resetPasswordMailJWT', this.username).then((response) => {
        if (response.status === 200) {
          this.isSend = true
        }
        this.$vs.notify({
          title: this.$t('password.recovery.notify.title'),
          text: this.$t('password.recovery.notify.text'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'success'
        })
      })
    }
  }
}
</script>
